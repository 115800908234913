import {
  LinesheetsTomorrowCrewOrBrandGuard,
  PriceManagementTomorrowCrewGuard,
  ProductAttributesTomorrowCrewOrBrandGuard,
  PurchaseOrdersTomorrowCrewOrBrandGuard,
  PriceSetupTomorrowCrewGuard,
  UserIsInDBGuard,
  CustomerIsInDBGuard,
  CompanyHashIsValid,
  UserIsBrandOrTomorrowCrew,
  LinesheetSettingsTomorrowCrewGuard,
  ClientManagementTomorrowCrewGuard,
  BookingSystemTomorrowCrewGuard,
  InboundProcessTomorrowCrewGuard,
} from "src/guards/role-based-guard";

// Linesheets Guard - Userg Group: Brand + Tomorrow Crew + Permissions
export const withLinesheetsGuard = (Component) => (props) =>
  (
    <LinesheetsTomorrowCrewOrBrandGuard>
      <Component {...props} />
    </LinesheetsTomorrowCrewOrBrandGuard>
  );

// Price Management Guard - User Group: Tomorrow Crew
export const withPriceManagementGuard = (Component) => (props) =>
  (
    <PriceManagementTomorrowCrewGuard>
      <Component {...props} />
    </PriceManagementTomorrowCrewGuard>
  );

// Product Attributes Guard - User Group: Brand + Tomorrow Crew + Permissions
export const withProductAttributesGuard = (Component) => (props) =>
  (
    <ProductAttributesTomorrowCrewOrBrandGuard>
      <Component {...props} />
    </ProductAttributesTomorrowCrewOrBrandGuard>
  );

// Purchase Orders Guard - User Group: Brand + Tomorrow Crew + Permissions
export const withPurchaseOrdersGuard = (Component) => (props) =>
  (
    <PurchaseOrdersTomorrowCrewOrBrandGuard>
      <Component {...props} />
    </PurchaseOrdersTomorrowCrewOrBrandGuard>
  );

// Price Setup Guard - User Group: Tomorrow Crew + Permissions
export const withPriceSetupGuard = (Component) => (props) =>
  (
    <PriceSetupTomorrowCrewGuard>
      <Component {...props} />
    </PriceSetupTomorrowCrewGuard>
  );

// Linesheet Settings Guard - User Group: Tomorrow Crew + Permissions
export const withLinesheetSettingsGuard = (Component) => (props) =>
  (
    <LinesheetSettingsTomorrowCrewGuard>
      <Component {...props} />
    </LinesheetSettingsTomorrowCrewGuard>
  );

// Client Management Guard - User Group: Tomorrow Crew + Permissions
export const withClientManagementGuard = (Component) => (props) =>
  (
    <ClientManagementTomorrowCrewGuard>
      <Component {...props} />
    </ClientManagementTomorrowCrewGuard>
  );

// Linesheet Settings Guard - User Group: Tomorrow Crew + Permissions
export const withBookingSystemGuard = (Component) => (props) =>
  (
    <BookingSystemTomorrowCrewGuard>
      <Component {...props} />
    </BookingSystemTomorrowCrewGuard>
  );

export const withUserIsInDBRoleGuard = (Component) => (props) => {
  return (
    <UserIsInDBGuard>
      <Component {...props} />
    </UserIsInDBGuard>
  );
};

export const withCustomerGuard = (Component) => (props) => {
  // TODO: Add guard check wrapper
  return (
    <CustomerIsInDBGuard>
      <Component {...props} />
    </CustomerIsInDBGuard>
  );
};

export const withCompanyHashGuard = (Component) => (props) => {
  // TODO: Add guard check wrapper
  return (
    <CompanyHashIsValid>
      <Component {...props} />
    </CompanyHashIsValid>
  );
};

export const withBrandOrTomorrowCrewGuard = (Component) => (props) => {
  return (
    <UserIsBrandOrTomorrowCrew>
      <Component {...props} />
    </UserIsBrandOrTomorrowCrew>
  );
};

export const withInboundProcessGuard = (Component) => (props) =>
  (
    <InboundProcessTomorrowCrewGuard>
      <Component {...props} />
    </InboundProcessTomorrowCrewGuard>
  );
